import * as React from "react";
import Layout from "../components/Layout";

import Seo from "../components/General/Seo";
import useSiteMetadata from "../components/SiteMetadata";


const Calendar = () => {
  const { meta, social } = useSiteMetadata();
  return (
    <Layout darkMenu={`true`} backgroundColour={`light`}>
      <Seo
        title={"Calendar" + meta.sitePageTitleSep + meta.siteTitle}
        slug={meta.siteUrl + "/calendar/"}
        description={meta.siteDescription}
        image={meta.siteImage}
        twitterTitle={"Calendar"}
        facebookTitle={"Calendar"}
        facebookAppID={social.facebookAppID}
        twitterAccount={social.twitterAccount}
        hideSEO={true}
      />
      <div className="py-5">
        <div className="container-fluid pt-5">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h2>Event Calendar</h2>
              <iframe
                className="embed-responsive-item"
                src="https://airtable.com/embed/shrYRa3xU5ndHpjG0?backgroundColor=blue&viewControls=on"
                frameborder="0"
                title="Calendar"
                style={{
                  background: "transparent",
                  border: "1px solid #ccc",
                  height: "80vh",
                  width: "100%",
                }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Calendar;
